import "./Main-Intro.css";

const MainIntro = () => {
  return (
    <div className="main-intro">
      <div className="main-intro-img"></div>
      <div className="main-intro-text">
        <h1 className="main-intro-title">
          100세 시대, 당신의 행복을 위한 품격있는 서비스!
        </h1>
        <p className="main-intro-desc">
          일반적인 간병의 개념을 넘어
          <br /> <span>삶의 질 </span>향상을 진정으로 추구하는
          <br />
          프리미엄 나들이요양원 입니다
        </p>
        <a href="#maps" className="main-intro-link">
          나들이요양원 위치 바로가기
        </a>
      </div>
    </div>
  );
};

export default MainIntro;
