import "./Intro.css";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Map from "../../components/map/Map";
import Blog from "../../components/blog/Blog";
import PageTitle from "../../components/PageTitle";

const Intro = () => {
  return (
    <>
      <PageTitle title="인사말" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Header />
            <div className="intro-container">
              <div className="intro-container-main-img"></div>
              <div className="intro-container-main-text">
                <a
                  className="intro-container-text-link"
                  href="tel:032-546-4000"
                >
                  <strong>친절상담</strong> 032-546-4000
                  <br />
                </a>
                <h5>
                  요양원 비용에서 등급까지!
                  <br />
                  무엇이든 물어보세요
                </h5>
                <strong className="intro-container-text-str">
                  "요양원 믿을 수 있을까?"
                </strong>
                <p className="intro-container-text-desc">
                  나들이요양원 원장 김은이
                  <br />
                  <span>의료복지 전문가, 간호전문 케어</span>
                </p>
                <p className="intro-container-text-desc">
                  대학병원 간호사 출신
                  <br />
                  사회복지 석박사
                  <br />現 부평구 최우수(A등급) 즐거운요양원 원장
                </p>
                <div className="intro-container-class-img"></div>
              </div>
              <div className="intro-container-book">
                <div className="intro-container-book-text">
                  <h1>나들이요양원 인사말</h1>
                  <p className="intro-container-book-desc">
                    <h3>"즐거운 봄 나들이"</h3>
                    현대사회는 돌봄을 가족이 전적으로 하기 어려운 시대에 살고 있습니다.  그러나 많은 어르신들이 "요양원은 죽으러 가는 곳이다"라고 안간다고 하십니다. 이럴때 자녀들의 고민은 깊어만 갑니다. 생각해보면 이제 우리는 노년기에 손길이 필요할 수밖에 없어 요양원 생활을 피하기 어려운 것이 현실입니다.
                  </p>
                  <p className="intro-container-book-desc">
                    <h4>"피할수 없으면 즐기자"</h4>
                    이제 요양원은 죽으러가는 곳이 아니라 인생에서 마지막으로 집을 떠나 나들이를 간다고 생각해 보세요. 그동안 누군가를 돌보며 살았던 우리의 부모님이 이제는 누군가의 따듯한 사랑과 돌보을 받는 그곳으로 봄나들이를 가는걸로 생각의 전환을 한다면 어르신과 가족 그리고 우리 모두가 미소지을수 있습니다.
                  </p>
                  <p className="intro-container-book-desc">
                    이런 생각으로 2021년 2월 인천 계양구에 나들이 요양원을 설립하게 되었습니다. 우리 나들이요양원은 인생의 동반자로서 이웃사랑을 실천하여 어르신들의 행복한 나들이를 만들어 가도록 최선을 다하겠습니다.
                    <h5>나들이 요양원 임직원 일동</h5>
                  </p>
                </div>
              </div>
            </div>
            <Blog />
            <Map />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Intro;
