import "./Info.css";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Map from "../../components/map/Map";
import { KindContact } from "../../components/detail/Detail";
import Blog from "../../components/blog/Blog";
import PageTitle from "../../components/PageTitle";

const Info = () => {
  return (
    <>
      <PageTitle title="입소 및 비용" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Header />
            <div className="info-container">
              <div className="info-container-main-img"></div>
              <KindContact />
            </div>
            <div className="info-container-box">
              <div className="info-container-box-how">
                <h1 className="info-container-box-how-title">입소안내</h1>
                <p className="info-container-box-how-desc">
                  국민건강보험공단에서 치매등급(시설등급)을
                  <br />
                  받으시면 국가지원을 받으실 수 있습니다.
                </p>
                <span className="info-container-box-how-bar">
                  국민건강보험공단 | 1577-1000
                </span>
                <p className="info-container-box-how-desc">
                  1등급~5등급 까지 구분되어 있으며, 시설등급을
                  <br />
                  받으시면 어느등급이든 이용가능합니다.
                </p>
              </div>
              <div className="info-container-box-need">
                <h1 className="info-container-box-need-title">
                  입소 시 준비물
                </h1>
                <p className="info-container-box-need-desc">
                  ✓ 장기요양인정서(시설등급 표시 및 유효기간 확인)
                  <br />
                  ✓ 코로나 '음성'확인 문자 혹은 확인서
                  <br />
                  ✓ 전염성 검사 확인서(B형간염,매독,결핵검사,피부병)
                  <br />✓ 개인소지품(입으시던 여벌옷과 속옷 서너 벌, 전기면도기
                  등)
                </p>
                <p className="info-container-box-need-desc">
                  ※ 전화해 주시면 자세하게 안내해 드립니다.
                </p>
                <span className="info-container-box-need-bar">
                  궁금한 점은 전화주세요 | 010-9951-6061
                </span>
              </div>
            </div>
            <div className="info-container-cost">
              <div className="info-container-cost-text">
                <h1 className="info-container-cost-text-title">요양원 비용</h1>
                <p className="info-container-cost-text-desc">
                  건강보험공단에서 정한 등급별 본인부담금은 모든 요양원이
                  동일합니다.
                  <br className="cost-info-br" />
                  <span> 본인부담금</span>에 <span>식대</span>가 추가되어 비용을
                  산정합니다.
                  <strong> (아래 표는 30일 기준입니다.)</strong>
                </p>
                <div className="info-container-cost-text-img"></div>
                <p className="info-container-cost-text-desc">
                  보통 심한 와상환자가 아니고 경증치매의 경우 3등급이며,
                  <br />
                  <span>
                    월 비용 약 60만원대로
                    <br />
                    식비, 기저귀, 프로그램 및 기능회복훈련 모두 포함
                  </span>
                  입니다.
                  <br />
                  <br />
                  *경감대상자는 건강보험료에 따라 결정되어지며, 국민건강보험에서
                  확인 가능합니다.
                </p>
              </div>
            </div>
            <Blog />
            <Map />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Info;
