import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-logo"></div>
      <p className="footer-info">
        국민건강보험공단 장기요양기관 나들이요양원
        <br />
        tel. 032-546-4000 fax. 032-555-1600
        <br />
        adress. 인천 계양구 오조산로7번길 6-8
        <br />
        (작전동 유영엠프레스 빌딩 4층)
        <br /> COPYRIGHT(C) 나들이요양원. ALL RIGHT RESERVED
      </p>
    </footer>
  );
};

export default Footer;
