import './Map.css';
import { RenderAfterNavermapsLoaded, NaverMap, Marker } from 'react-naver-maps';
import { mapIcon } from '../../img/Icons';
import { IntroBar } from '../../components/detail/Detail';

function NaverMapAPI() {
  const navermaps = window.naver.maps;
  return (
    <NaverMap
      className="naver-map"
      mapDivId={'maps-getting-started-uncontrolled'} // default: react-naver-map
      defaultCenter={{ lat: 37.531746473060174, lng: 126.737656475465 }} // 지도 초기 위치
      defaultZoom={16} // 지도 초기 확대 배율
      zoomControl={true}
      scrollWheel={false}
    >
      <Marker
        key={1}
        position={new navermaps.LatLng(37.531746473060174, 126.737656475465)}
        animation={1}
        title="나들이요양원"
        onClick={() => {
          window.open(
            'https://m.place.naver.com/place/1738987521/location?entry=pll&subtab=location'
          );
        }}
      />
    </NaverMap>
  );
}

const Map = () => {
  return (
    <>
      <div className="after-review-img"></div>
      <IntroBar text="오시는 길" Icon={mapIcon} />
      <div className="map-container">
        {/* <h1 className="map-container-title">나들이 요양원 오시는 길</h1> */}
        <div className="map-container-map" id="maps">
          <RenderAfterNavermapsLoaded
            className="home-container-map_box_map"
            ncpClientId="2xo4rokjm1"
            error={<p>Maps Load Error</p>}
            loading={<p>Maps Loading...</p>}
          >
            <NaverMapAPI />
          </RenderAfterNavermapsLoaded>
        </div>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://m.place.naver.com/place/1738987521/location?entry=pll&subtab=location"
          className="map-naver-link-mobile"
        >
          네이버 지도 보기
        </a>
        <div className="map-container-text">
          <h1 className="map-container-text-addr">
            주소 : 인천광역시 계양구 오조산로7번길 6-8
            <br />
            유영엠프레스 빌딩 4층 (지번: 작전동 910-9)
          </h1>
          <div className="map-container-text-img"></div>
          <p className="map-container-text-desc">
            ※ 메디플렉스 세종병원 5분거리
            <br />
            ※ 종합병원 한림병원 10분거리
            <br />
            ※ 이마트 계양점 도보 5분거리
          </p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://map.naver.com/v5/entry/place/1738987521?c=14108374.6253100,4513499.4469718,13,0,0,0,dh"
            className="map-naver-link-desk"
          >
            네이버 지도 보기
          </a>
        </div>
        <a className="map-container-text-link" href="tel:032-546-4000">
          <strong>상담전화</strong> 032-546-4000
          <br />
          <span>지금 통화하기 버튼</span>
        </a>
      </div>
    </>
  );
};

export default Map;
