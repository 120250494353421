import './Blog.css';

const Blog = () => {
  return (
    <div className="facility-container-blog">
      <h1 className="facility-container-blog-title">
        나들이요양원 블로그 바로가기
      </h1>
      <div className="facility-container-blog-list">
        <div className="facility-container-blog-list-item">
          <span>&raquo;</span>
          <a
            target="_blank"
            href="https://blog.naver.com/nadrinursing17/222373681192"
            rel="noreferrer"
          >
            의료복지 전문가가 운영하는 계양구 나들이요양원
          </a>
        </div>
        <div className="facility-container-blog-list-item">
          <span>&raquo;</span>
          <a
            target="_blank"
            href="https://blog.naver.com/nadrinursing17/222360637709"
            rel="noreferrer"
          >
             '나들이요양원' 호텔급 시설 소개
          </a>
        </div>
        <div className="facility-container-blog-list-item">
          <span>&raquo;</span>
          <a
            target="_blank"
            href="https://blog.naver.com/arumdri58/222368053200"
            rel="noreferrer"
          >
            계양구 요양원 주목! 치매전문케어 '나들이요양원'
          </a>
        </div>
      </div>
    </div>
  );
};

export default Blog;
