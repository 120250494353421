import Blog from "../../components/blog/Blog";
import { IntroBar, KindContact } from "../../components/detail/Detail";
import {
  CareImgBox,
  CareImgBoxFood,
  CareImgBoxFood2,
} from "../../components/facility-intro/Facility-intro";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Map from "../../components/map/Map";
import PageTitle from "../../components/PageTitle";
import RehabilitationIntro from "../../components/rehabilitation-intro/Rehabilitation-Intro";
import { behabilitationIcon } from "../../img/Icons";
import "./Rehabilitation.css";

const Rehabilitation = () => {
  return (
    <>
      <PageTitle title="케어서비스" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Header />
            <div className="rehabilitation-container">
              <div className="rehabilitation-container-main-img"></div>
              <KindContact />
            </div>
            <IntroBar text="케어서비스" Icon={behabilitationIcon} />
            <RehabilitationIntro />
            <CareImgBox />
            <div className="care-plus">
              <div className="care-plus-img"></div>
              <h1 className="care-plus-title">
                심폐소생술, 심장자동제세동기 교육 실시
              </h1>
              <p className="care-plus-desc">
                나들이요양원은 이 외에도 다양한 전문케어 교육을 매달 실시하고
                있습니다.
              </p>
            </div>
            <IntroBar text="전문적인 식단" Icon={behabilitationIcon} />
            <CareImgBoxFood />
            <CareImgBoxFood2 />
            <Blog />
            <Map />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Rehabilitation;
