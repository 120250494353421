import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import dotenv from "dotenv";
import Intro from "../screens/intro/Intro";
import Main from "../screens/main/Main";
import Facility from "../screens/facility/Facility";
import Care from "../screens/care/Care";
import Info from "../screens/info/Info";
import Rehabilitation from "../screens/rehabilitation/Rehabilitation";
import { HelmetProvider } from "react-helmet-async";

dotenv.config();

export const RootRoutes = () => {
  return (
    <HelmetProvider>
      <Router basename="/">
        <Switch>
          <Route path="/rehabilitation" component={Rehabilitation} />
          <Route path="/info" component={Info} />
          <Route path="/care" component={Care} />
          <Route path="/intro" component={Intro} />
          <Route path="/facility" component={Facility} />
          <Route path="/" component={Main} />
        </Switch>
      </Router>
    </HelmetProvider>
  );
};
