import "./Facility-intro.css";

export const FacilityIntro = () => {
  return (
    <>
      <div className="facility-intro">
        <div className="facility-intro-text">
          <h1 className="facility-intro-title">
            직주근접성 우수! 도심형 최신 요양원
          </h1>
          <p className="facility-intro-desc">
            인천 계양구 중심가에 위치한 나들이 요양원
            <br />
            채광이 우수한 건물 한 층 전체 사용
            <br />
            장애인 화장실, 엘레베이터 구비로 안전 강화
            <br />
          </p>
          <span className="facility-intro-sub-bar">
            협력병원 | 한림병원, 세종병원, 인천성모병원, 다니엘병원
          </span>
          <strong className="facility-intro-sub-notice">
            &#8251; 종합병원 세종병원, 한림병원 도보 5분 거리
          </strong>
        </div>
      </div>
    </>
  );
};

export const FacilityImg = () => {
  return (
    <div className="facility-img">
      <div className="facility-img-text">
        <h1 className="facility-img-text-title">
          2021년 신축 호텔식 요양원
          <br />
          깨끗하고 넓은 시설
        </h1>
        <p className="facility-img-text-desc">
          좋은 자재, 예쁜 인테리어, 안전을 강화한 설계로 만든 최고급 요양원
          <br />
          선진국형 호텔식요양원으로 방 안에 화장실을 구비한 프리미엄 시설
        </p>
      </div>
      <div className="facility-img-img">
        <div className="facility-img-img-item1"></div>
        <div className="facility-img-img-item2"></div>
        <div className="facility-img-img-item3"></div>
        <div className="facility-img-img-item4"></div>
        <div className="facility-img-img-item5"></div>
        <div className="facility-img-img-item6"></div>
      </div>
    </div>
  );
};

export const FacilityImg2 = () => {
  return (
    <div className="facility-img">
      <div className="facility-img-text">
        <h1 className="facility-img-text-title">
          조명과 고급스러운 시설물
          <br />
          어르신들 삶의 만족도 상승
        </h1>
        <p className="facility-img-text-desc">
          조명과 디테일한 소품들, 그리고 구비해놓은 그림들은
          <br />
          어르신들 마음의 안정과 심미적 만족도를 증가시킵니다.
        </p>
      </div>
      <div className="facility-img-img">
        <div className="facility-img-img2-item1"></div>
        <div className="facility-img-img2-item2"></div>
        <div className="facility-img-img2-item3"></div>
        <div className="facility-img-img2-item4"></div>
        <div className="facility-img-img2-item5"></div>
        <div className="facility-img-img2-item6"></div>
      </div>
    </div>
  );
};

export const FacilityImg3 = () => {
  return (
    <div className="facility-img">
      <div className="facility-img-text">
        <h1 className="facility-img-text-title">
          의료복지를 실현하는 기구 구비
          <br />
          안전한 시설물
        </h1>
        <p className="facility-img-text-desc">
          병원에서 사용하는 의료복지 시설물과 보다 안전한 시설물로
          <br />
          어르신들의 건강을 지킵니다.
        </p>
      </div>
      <div className="facility-img-img">
        <div className="facility-img-img3-item1"></div>
        <div className="facility-img-img3-item2"></div>
        <div className="facility-img-img3-item3"></div>
        <div className="facility-img-img3-item4"></div>
        <div className="facility-img-img3-item5"></div>
        <div className="facility-img-img3-item6"></div>
      </div>
    </div>
  );
};

export const CareImgBox = () => {
  return (
    <div className="facility-img">
      <div className="facility-img-text">
        <h1 className="facility-img-text-title">
          전문적인 케어서비스를 위한 직원 교육
        </h1>
        <p className="facility-img-text-desc">
          나들이요양원은 전문적이고 체계적인 개별서비스를 제공하기 위하여
          <br />
          전직원에게 급여제공지침을 비롯하여 다양한 직무교육을 실시합니다.
        </p>
      </div>
      <div className="facility-img-img">
        <div className="care-img-img-item1"></div>
        <div className="care-img-img-item2"></div>
        <div className="care-img-img-item3"></div>
        <div className="care-img-img-item4"></div>
        <div className="care-img-img-item5"></div>
        <div className="care-img-img-item6"></div>
      </div>
    </div>
  );
};

export const CareImgBoxFood = () => {
  return (
    <div className="facility-img">
      <div className="facility-img-text" style={{ textAlign: "center" }}>
        <h1 className="facility-img-text-title">
          노년기를 위한 식단제공 <br />
          위생적인 영양식단 제공
        </h1>
        <p className="facility-img-text-desc">
          장기요양평가 최우수(A등급)기관
          <br />
          전문인력이 공급하는 식사
          <br />
          위생적인 자체조리시설 보유
          <br />
          균형잡힌 영양식 제공
        </p>
      </div>
      <div className="facility-img-img">
        <div className="care-food-img-item1"></div>
        <div className="care-food-img-item2"></div>
        <div className="care-food-img-item3"></div>
        <div className="care-food-img-item4"></div>
        <div className="care-food-img-item5"></div>
        <div className="care-food-img-item6"></div>
      </div>
    </div>
  );
};

export const CareImgBoxFood2 = () => {
  return (
    <div className="facility-img">
      <div className="facility-img-text" style={{ textAlign: "center" }}>
        <h1 className="facility-img-text-title">
          어르신 맞춤 식단, 5찬 프리미엄 식단
        </h1>
        <p className="facility-img-text-desc">
          죽과 다진 반찬, 일반식 등 맞춤 식단을 제공하고 일반식의
          <br />
          경우는 반찬을 5찬으로 하여 프리미엄 식단을 제공합니다.
        </p>
      </div>
      <div className="facility-img-img">
        <div className="care-food-img-item7"></div>
        <div className="care-food-img-item8"></div>
        <div className="care-food-img-item9"></div>
        <div className="care-food-img-item10"></div>
        <div className="care-food-img-item11"></div>
        <div className="care-food-img-item12"></div>
      </div>
    </div>
  );
};
