import Blog from "../../components/blog/Blog";
import { CareIntro } from "../../components/care-intro/Care-Intro";
import { IntroBar, KindContact } from "../../components/detail/Detail";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Map from "../../components/map/Map";
import PageTitle from "../../components/PageTitle";
import { CareSlick } from "../../components/slicks/Slicks";
import { behabilitationIcon } from "../../img/Icons";
import "./Care.css";

const Care = () => {
  return (
    <>
      <PageTitle title="프로그램" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Header />
            <div className="care-container">
              <div className="care-container-main-img"></div>
              <KindContact />
            </div>
            <IntroBar text="치매프로그램" Icon={behabilitationIcon} />
            <CareIntro />
            <div className="care-container-box-items">
              <div className="care-container-box-item">
                <div className="care-container-box-item-img1"></div>
                <p>치매프로그램 - 그림그리기</p>
              </div>
              <div className="care-container-box-item">
                <div className="care-container-box-item-img2"></div>
                <p>노래부르고 대화하기</p>
              </div>
              <div className="care-container-box-item">
                <div className="care-container-box-item-img3"></div>
                <p>사회복지사와 함께 프로그램 하기</p>
              </div>
              <div className="care-container-box-item">
                <div className="care-container-box-item-img4"></div>
                <p>콩고르기 등 맞춤 프로그램</p>
              </div>
            </div>
            <CareSlick />
            <Blog />
            <Map />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Care;
