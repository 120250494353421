import { IntroBar, KindContact } from "../../components/detail/Detail";
import {
  FacilityImg,
  FacilityImg2,
  FacilityImg3,
  FacilityIntro,
} from "../../components/facility-intro/Facility-intro";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Map from "../../components/map/Map";
import { facilityIcon } from "../../img/Icons";
import "./Facility.css";
import Blog from "../../components/blog/Blog";
import PageTitle from "../../components/PageTitle";

const Facility = () => {
  return (
    <>
      <PageTitle title="시설안내" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Header />
            <div className="facility-container">
              <div className="facility-container-main-img"></div>
              <KindContact />
            </div>
            <IntroBar text="시설안내" Icon={facilityIcon} />
            <FacilityIntro />
            <div className="hospital">
              <div className="hospital-img"></div>
              <p className="hospital-desc">
                나들이요양원 창문너머 세종병원이 바로 보입니다
              </p>
            </div>
            <FacilityImg />
            <div className="facility-container-box-items">
              <div className="facility-container-box-item">
                <div className="facility-container-box-item-text"></div>
                <div className="facility-container-box-item-img1"></div>
              </div>
            </div>
            <FacilityImg2 />
            <div className="facility-container-box-items">
              <div className="facility-container-box-item">
                <div className="facility-container-box-item-text"></div>
                <div className="facility-container-box-item-img2"></div>
              </div>
            </div>
            <FacilityImg3 />
            <div className="facility-container-box-items">
              <div className="facility-container-box-item">
                <div className="facility-container-box-item-text"></div>
                <div className="facility-container-box-item-img3"></div>
              </div>
            </div>
            <Blog />
            <Map />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Facility;
