import { RehabilitationSlick } from "../slicks/Slicks";
import "./Rehabilitation-Intro.css";

const RehabilitationIntro = () => {
  return (
    <div className="rehabilitation-intro">
      <div className="rehabilitation-intro-text">
        <h1 className="rehabilitation-intro-text-title">
          대학병원 경력의 간호사가 운영
          <br />
          전문적이고 체계화된 케어
        </h1>
        <p className="rehabilitation-intro-text-desc">
          간호전문케어로 질 좋은 서비스! 전문 촉탁의사 정기 방문 진찰
          <br />
          장기요양평가 최우수(A등급)기관 전문인력이 제공하는 케어
        </p>
      </div>
      <RehabilitationSlick />
    </div>
  );
};

export default RehabilitationIntro;
